import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getDoenca } from "../actions/actions";

class DiseaseSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doenca: null
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const doencaId = this.props.location.state.doenca;
      const doencaDetalhe = getDoenca(doencaId);
      this.setState({ doenca: doencaDetalhe });
    }
  }

  render() {
    return (
      <div>
        <div className="Title">
          <img src="./images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>DOENÇA</h2>
        </div>
        <Card className="App">
          <CardContent>
            {this.state.doenca && <Typography color="textSecondary" gutterBottom>
              { this.state.doenca.nome && <h2>{ this.state.doenca.nome }</h2> }<br/>
              { this.state.doenca.definicao &&
                <div>
                  <h3>Definição</h3>
                  <p>{ this.state.doenca.definicao }</p>
                  <br/>
                </div>
              }
              { this.state.doenca.caracteristicasDefinidoras &&
                <div>
                  <h3>Características Definidoras</h3>
                  { this.state.doenca.caracteristicasDefinidoras.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.fatoresDeRisco &&
                <div>
                  <h3>Fatores de Risco</h3>
                  { this.state.doenca.fatoresDeRisco.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.fatoresRelacionados &&
                <div>
                  <h3>Fatores Relacionados</h3>
                  { this.state.doenca.fatoresRelacionados.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.intervencoesPrincipais &&
                <div>
                  <h3>Intervenções Principais</h3>
                  { this.state.doenca.intervencoesPrincipais.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.intervencoesSugeridas &&
                <div>
                  <h3>Intervenções Sugeridas</h3>
                  { this.state.doenca.intervencoesSugeridas.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
            </Typography> }
          </CardContent>
          <CardActions>
            <Link exact to="/" style={{ textDecoration: "none" }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default DiseaseSearch;
