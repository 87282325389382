import React, { Component } from 'react';
import '../App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './Home';
import Disease from './Disease';
import DiseaseSearch from './DiseaseSearch';

class App extends Component {
  
  render() {

    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/disease" component={Disease} />
          <Route path="/diseaseSearch" component={DiseaseSearch} />
        </div>
      </Router>
    );
  }
}

export default App;
